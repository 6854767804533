import React from 'react'

import { PageLayout, OrderConfirmation } from '../../components'

const OrderConfirmationTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <PageLayout lang={lang} switcher={page}>
      <OrderConfirmation lang={lang} />
    </PageLayout>
  )
}

export default OrderConfirmationTemplate
